import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import MiniCardWithBgImg from "../../components/MiniCardWithBgImg";
import "./BlurCardList.scss";
import Button from "../../components/Button";
import RightArrow from "../../assets/Icons/go.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";

const BlurCardList = (props) => {
  const [isHover, setIsHover] = useState(false);
  const [showRemainingComponent, setShowRemainingComponent] = useState(false);
  let showLoadMore = true;
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  const sliceArray = props.BlurCardListData.cardsData.slice(0, 3);
  const sliceArrayIndustry = props.BlurCardListData.cardsData.slice(0, 5);
  const sliceArrayFinal = props.componenetName
    ? sliceArrayIndustry
    : sliceArray;
  const remainingArray = props.BlurCardListData.cardsData.slice(3, 10);
  const remainingArrayIndustry = props.BlurCardListData.cardsData.slice(5, 10);
  const finalArray = props.componenetName
    ? remainingArrayIndustry
    : remainingArray;
  return (
    <div className="body-container">
      <Grid
        container
        className={props.coponentCount ? "justify-grid-comp" : ""}
        direction="row"
        spacing={props.coponentCount ? "" : 4}
      >
        {props.loadMore
          ? sliceArrayFinal.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={item.text ? 4 : 6}
                md={item.text ? 2 : 4}
                className={
                  props.componenetName === "industry"
                    ? "card crd-spc margin-style"
                    : "card crd-spc"
                }
              >
                <MiniCardWithBgImg
                  MiniCardBgImg={item.image}
                  MiniCardBgImgId={index + props.componentId}
                  title={item.title}
                  subtitle={item.subTitle}
                  customClassName={item.customClass}
                  label={item.btnLabel}
                  route={item.route}
                  button={item.button}
                  tooltip="yes"
                  RightArrowShow={item.RightArrowShow}
                  textColor={item.text}
                  cardHgt={item.cardHgt}
                />
              </Grid>
            ))
          : props.BlurCardListData.cardsData.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={item.text ? 4 : 6}
                md={item.text ? 2 : 4}
                className={
                  props.componenetName === "industry"
                    ? "card crd-spc margin-style"
                    : "card crd-spc"
                }
              >
                <MiniCardWithBgImg
                  MiniCardBgImg={item.image}
                  MiniCardBgImgId={index + props.componentId}
                  title={item.title}
                  subtitle={item.subTitle}
                  customClassName={item.customClass}
                  label={item.btnLabel}
                  route={item.route}
                  button={item.button}
                  tooltip="yes"
                  RightArrowShow={item.RightArrowShow}
                  textColor={item.text}
                  cardHgt={item.cardHgt}
                />
              </Grid>
            ))}
        {props.BlurCardListData.lastCardData && (
          <Grid item xs={12} sm={6} md={4} className="card crd-spc">
            <MiniCardWithBgImg
              MiniCardBgImg={props.BlurCardListData.lastCardData.image}
              MiniCardBgImgId="100"
              title={props.BlurCardListData.lastCardData.title}
              subtitle={props.BlurCardListData.lastCardData.subTitle}
              variant={props.BlurCardListData.lastCardData.variant}
              customClassName="three-mini-card-btn mt-60"
              cardCustomClass={props.BlurCardListData.lastCardData.customClass}
              label={props.BlurCardListData.lastCardData.label}
              route={props.BlurCardListData.lastCardData.route}
              tooltip="yes"
            />
          </Grid>
        )}
        {props.loadMore && showRemainingComponent
          ? finalArray.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={item.text ? 4 : 6}
                md={item.text ? 2 : 4}
                className={
                  props.componenetName === "industry"
                    ? "card crd-spc margin-style"
                    : "card crd-spc"
                }
              >
                <MiniCardWithBgImg
                  MiniCardBgImg={item.image}
                  MiniCardBgImgId={index + props.componentId + 1}
                  title={item.title}
                  subtitle={item.subTitle}
                  customClassName={item.customClass}
                  label={item.btnLabel}
                  route={item.route}
                  button={item.button}
                  tooltip="yes"
                  textColor={item.text}
                  cardHgt={item.cardHgt}
                  RightArrowShow={item.RightArrowShow}
                />
              </Grid>
            ))
          : ""}
        {props.loadMore && showLoadMore ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
              className="mt-60"
              role="presentation"
              onClick={() => {
                setShowRemainingComponent(!showRemainingComponent);
              }}
              style={{ display: "inline-block" }}
            >
              <Button
                icon={
                  <img
                    className={`effect-btn-svgicon rotate-90`}
                    src={!isHover ? RightArrow : RightArrowWhite}
                    alt="MORE CLIENT STORIES"
                  />
                }
                customClassName="info-view-resize-btn"
                color="primary"
                variant="outlined"
                label={
                  props.componenetName === "industry"
                    ? "Load more industries"
                    : props.loadMoreLabel
                    ? showRemainingComponent
                      ? "Show Less"
                      : props.loadMoreLabel
                    : "Load more services"
                }
                // tip="LOAD MORE"
                tooltip="no"
              />
              <div className="mt-30" />
            </div>
          </div>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
};

export default BlurCardList;
